import React, { Component } from "react";
import {
    Typography,
} from '@material-ui/core';



export default class AboutUs extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        // const { titleForSearch, tutorials, currentTutorial, currentIndex } = this.state;
        return (
            <div>
                <h4>
                    About Us
                </h4>
            </div>
        )
    }
}
